
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-scrollbar-vertical {
    box-shadow: inset 0 0 2px rgba(0,0,0,0.3); background-color: #FFF; border-radius: 3px; width: 3px;
}