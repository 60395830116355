.read-more-less--more-white,
.read-more-less-less-white {
  color: #FFF;
  /* text-decoration: underline; */
  text-transform: capitalize;
  display: block;
  text-align: right;
}
.read-more-less-more-black,
.read-more-less-less-black {
  color: #000;
  /* text-decoration: underline; */
  text-transform: capitalize;
  display: block;
  text-align: right;
}
