#desc_scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#desc_scroll::-webkit-scrollbar
{
	width: 3px;
	background-color: #F5F5F5;
}

#desc_scroll::-webkit-scrollbar-thumb
{
	background-color: #000000;
}
