.swiper {
    width: '100%';
    height: '100%';
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: '100%';
    height: '100%';
    object-fit: cover;
  }
  
  .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #ffffff;
    background: #363636;
    opacity: calc(80%);    
  }
  
  .swiper-pagination-bullet-active {
    opacity: calc(80%);
    color: #000;
    background: #f0f0f0;
  }