.overlay {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 15;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
}

.fill {
  flex: 1;
  background: transparent;
}

.drawer {
  position: absolute;
  background-color: #FFF;
  color: #000;
  z-index: 30;
  overflow-y: auto;
  overflow-x: hidden;
}

.menu--right {
  top: 0;
  right: 0;
}

.menu a {
  text-decoration: none;
  color: #777;
}

.menu a:hover,
.menu a:focus {
  color: #000;
}